<template>
    <CCard>
        <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(submit)">
                <CCardHeader>
                    <h4 class="m-0">{{ $t('pages.content.faqManagement.headers.edit') }}</h4>
                </CCardHeader>
                <CCardBody>
                    <b-overlay :show="loading" id="overlay-background" variant="white" opacity="0.85" blur="2px">
                        <b-row>
                            <b-col>
                                <h4>Deutsch</h4>
                                <validation-provider
                                    :name="$t('pages.content.faqManagement.form.question') + ' '"
                                    :rules="{ required: true, min: 3, max: 256 }"
                                    v-slot="validationContext"
                                >
                                    <b-form-group
                                        :label="$t('pages.content.faqManagement.form.question')"
                                    >
                                        <b-form-input
                                            v-model="form.question.de"
                                            type="text"
                                            :state="getValidationState(validationContext)"
                                            aria-describedby="question-de-feedback"
                                        />
                                        <b-form-invalid-feedback id="question-de-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                                <b-form-group :label="$t('pages.content.faqManagement.form.answer')">
                                    <vue-editor
                                        v-model="form.answer.de"
                                    ></vue-editor>
                                </b-form-group>
                            </b-col>
                            <b-col>
                                <h4>Englisch</h4>
                                <validation-provider
                                    :name="$t('pages.content.faqManagement.form.question')"
                                    :rules="{ required: true, min: 3, max: 256 }"
                                    v-slot="validationContext"
                                >
                                    <b-form-group
                                        :label="$t('pages.content.faqManagement.form.question')"
                                    >
                                        <b-form-input
                                            v-model="form.question.en"
                                            type="text"
                                            :state="getValidationState(validationContext)"
                                            aria-describedby="question-en-feedback"
                                        />
                                        <b-form-invalid-feedback id="question-en-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                                <b-form-group
                                    :label="$t('pages.content.faqManagement.form.answer')"
                                >
                                    <vue-editor
                                        v-model="form.answer.en"
                                    ></vue-editor>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <validation-provider
                            :name="$t('pages.vdi.apps.form.image').toString()"
                            :rules="{ required: false, max: 255 }"
                            v-slot="validationContext"
                        >
                            <b-form-group
                                label-cols-sm="12"
                                label-cols-md="3"
                            >
                                <template #label>
                                    {{ $t('pages.content.faqManagement.form.image') }}
                                    <font-awesome-icon icon="info-circle" v-b-popover.hover.top="$t('pages.content.faqManagement.form.hints.image')"/>
                                </template>
                                <b-input-group>
                                    <b-input-group-prepend>
                                        <b-input-group-text class="bg-white">
                                            <img :src="form.image" v-show="picture" width="20" height="20" @load="picture = true" @error="picture = false">
                                            <b-icon-question-circle v-show="!picture" class="text-orange"/>
                                        </b-input-group-text>
                                    </b-input-group-prepend>
                                    <b-form-input
                                        type="url"
                                        name="image"
                                        maxlength="255"
                                        :state="getValidationState(validationContext)"
                                        v-model="form.image"
                                        :placeholder="$t('pages.vdi.apps.form.image.placeholder')"
                                        aria-describedby="image-feedback"
                                    />
                                    <b-form-invalid-feedback id="image-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-input-group>
                            </b-form-group>
                        </validation-provider>
                        <validation-provider
                            :name="$t('pages.content.faqManagement.form.name')"
                            :rules="{ required: true, min: 3, max: 32 }"
                            v-slot="validationContext"
                        >
                            <b-form-group
                                label-cols-sm="12"
                                label-cols-md="3"
                                :label="$t('pages.content.faqManagement.form.name')"
                            >
                                <b-form-input
                                    v-model="form.name"
                                    :state="getValidationState(validationContext)"
                                    aria-describedby="name-feedback"
                                ></b-form-input>
                                <b-form-invalid-feedback id="name-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                        <validation-provider
                            :name="$t('pages.content.faqManagement.form.tags.label')"
                            :rules="{ required: true }"
                            v-slot="validationContext"
                        >
                            <b-form-group
                                label-cols-sm="12"
                                label-cols-md="3"
                                :label="$t('pages.content.faqManagement.form.tags.label')"
                            >
                                <b-form-tags
                                    input-id="tags-basic"
                                    v-model="form.tags"
                                    :placeholder="$t('pages.content.faqManagement.form.tags.placeholder')"
                                    separator=" ,;"
                                    :limit="20"
                                    remove-on-delete
                                    :state="getValidationState(validationContext)"
                                    aria-describedby="text-feedback"
                                ></b-form-tags>
                                <b-form-invalid-feedback id="text-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                        <b-form-group
                            label-cols-sm="12"
                            label-cols-md="3"
                        >
                            <template #label>
                                {{ $t('pages.content.faqManagement.form.public') }}
                                <font-awesome-icon icon="info-circle" v-b-popover.hover.top="$t('pages.content.faqManagement.form.hints.public')"/>
                            </template>
                            <b-form-select v-model="form.public">
                                <b-form-select-option :value="false">{{ $t('pages.content.faqManagement.values.public.no') }}</b-form-select-option>
                                <b-form-select-option :value="true">{{ $t('pages.content.faqManagement.values.public.yes') }}</b-form-select-option>
                            </b-form-select>
                        </b-form-group>
                    </b-overlay>
                </CCardBody>
                <CCardFooter class="d-flex justify-content-end">
                    <b-button type="submit" variant="orange">{{ $t("buttons.save") }}</b-button>
                </CCardFooter>
            </b-form>
        </validation-observer>
    </CCard>
</template>

<script>
import {VueEditor} from "vue2-editor";
import faqService from "../../../services/faqService";

export default {
    name: "EditFaq",
    props: ["id"],
    data: () => ({
        resourceTypes: [],
        resources: [],
        form: {
            name: '',
            question: {
                de: '',
                en: ''
            },
            answer: {
                de: '',
                en: ''
            },
            tags: [],
            image: '',
            public: false
        },
        picture: false,
        loading: false
    }),
    components: {
        VueEditor
    },
    mounted() {
        faqService.get(this.id).then(response => {
            this.form = Object.assign(this.form, response.data);
        }).catch();
    },
    methods: {
        getValidationState({dirty, validated, valid = null}) {
            return dirty || validated ? valid : null;
        },
        submit() {
            if(this.form.question.de === '' || this.form.question.en === '' || this.form.answer.de === '' || this.form.answer.en === '' || this.form.name === ''){
                this.$toastr.e("Bitte alle Felder ausfüllen!")
                return false;
            }
            if (!this.loading) {
                this.loading = true;
                faqService.edit(this.form).then(() => {
                    this.$toastr.s(this.$t('pages.content.faqManagement.messages.success.edited'));
                    this.$router.push({name: 'ContentFaqManagementList'});
                    this.loading = false;
                }).catch(error => {
                    this.errorHandler(error);
                    this.loading = false;
                });
            }
        }
    }
};
</script>

<style scoped>

</style>
